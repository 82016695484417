import React from "react";

const SuccessStories = () => {
  const stories = [
    {
      company: "ABC Tech",
      logo: "https://via.placeholder.com/80", // 회사 로고 이미지 URL
      title: "글로벌 팀 협업 강화",
      description:
        "Google Workspace를 도입한 이후, 전 세계에 흩어져 있는 팀원들이 더욱 쉽게 소통하고 협업할 수 있게 되었습니다. 특히 Google Meet와 Drive를 활용해 프로젝트 진행 속도가 30% 향상되었습니다.",
      person: "김민수, IT 매니저",
    },
    {
      company: "XYZ Retail",
      logo: "https://via.placeholder.com/80", // 회사 로고 이미지 URL
      title: "생산성 50% 증가",
      description:
        "Google Docs와 Sheets를 통해 문서 작업과 데이터 관리가 간소화되었고, Gmail과 Calendar로 업무 일정 관리가 체계화되었습니다. 덕분에 직원들의 생산성이 50% 이상 증가했습니다.",
      person: "이영희, 운영 팀장",
    },
    {
      company: "Startup Co.",
      logo: "https://via.placeholder.com/80", // 회사 로고 이미지 URL
      title: "비용 절감과 효율성 향상",
      description:
        "Google Workspace 덕분에 별도의 서버 관리 비용 없이 클라우드 기반으로 모든 업무를 처리할 수 있었습니다. 초기 스타트업에게 꼭 필요한 경제적인 솔루션입니다.",
      person: "박준호, 창업자",
    },
  ];

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      {/* Section Header */}
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          성공 사례
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Google Workspace를 통해 비즈니스 혁신을 이룬 고객들의 이야기를 확인하세요.
        </p>
      </div>

      {/* Stories Grid */}
      <div className="mt-12 grid gap-12 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
        {stories.map((story, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-8 hover:shadow-xl transition-shadow duration-300"
          >
            {/* Company Logo */}
            <div className="flex items-center justify-center mb-6">
              <img
                src={story.logo}
                alt={`${story.company} 로고`}
                className="w-20 h-20 object-contain"
              />
            </div>
            {/* Title */}
            <h3 className="text-xl font-semibold text-gray-800 mb-4 text-center">
              {story.title}
            </h3>
            {/* Description */}
            <p className="text-gray-600 mb-6">{story.description}</p>
            {/* Person Info */}
            <p className="text-sm text-gray-500 font-medium text-right">
              - {story.person}
            </p>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <h3 className="text-xl font-bold text-gray-800">
          Google Workspace로 성공 스토리를 만들어보세요!
        </h3>
        <p className="mt-4 text-gray-600">
          지금 바로 Google Workspace를 도입하고 비즈니스 혁신을 경험하세요.
        </p>
        <button className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300">
        <a
            href="https://forms.gle/ZKdgbNnfZRoTrZq57"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          더 알아보기
          </a>
        </button>
      </div>
    </section>
  );
};

export default SuccessStories;
