import React from "react";

const benefits = [
  {
    title: "Google Workspace 공식 리셀러",
    description:
      "Google Workspace Reseller 인증은 Google의 공식 파트너로서 고객에게 Google Workspace 제품을 판매하고 관리할 수 있는 권한을 제공합니다. 이를 통해 기업은 신뢰할 수 있는 파트너로 자리 잡을 수 있습니다.",
    icon: "✔️",
  },
  {
    title: "전문적인 기술 지원",
    description:
      "Google Workspace Reseller로서 고객의 문제를 신속하게 해결하고, Google Cloud 지원 포털을 통해 전문적인 기술 지원을 제공합니다.",
    icon: "🔧",
  },
  {
    title: "맞춤형 솔루션 제공",
    description:
      "고객의 비즈니스 요구에 맞는 맞춤형 Google Workspace 솔루션을 설계하고, 도입 및 운영 과정을 지원합니다.",
    icon: "📊",
  },
  {
    title: "데이터 마이그레이션 및 구축 지원",
    description:
      "기존 데이터와 시스템을 Google Workspace로 안전하게 이전하며, 구축 과정에서 발생하는 모든 문제를 해결합니다.",
    icon: "📂",
  },
  {
    title: "비용 효율적인 관리",
    description:
      "Google Workspace Reseller는 고객의 구독 및 결제를 통합적으로 관리하며, 효율적인 비용 구조를 제공합니다.",
    icon: "💰",
  },
];

const features = [
  {
    title: "Partner Sales Console",
    description:
      "Partner Sales Console을 사용하여 고객 계정, 구독, 결제 정보를 한 곳에서 관리할 수 있습니다. 이를 통해 고객 관리가 간소화되고 효율성이 극대화됩니다.",
  },
  {
    title: "Google Cloud 지원 포털",
    description:
      "Google Cloud 지원 포털을 통해 고객의 문제를 빠르게 해결하고, 기술적 지원 요청을 관리할 수 있습니다.",
  },
  {
    title: "다양한 결제 옵션 제공",
    description:
      "다양한 통화와 결제 옵션을 지원하여 고객이 원하는 방식으로 구독 비용을 처리할 수 있습니다.",
  },
];

const faq = [
  {
    question: "Google Workspace Reseller 인증은 무엇인가요?",
    answer:
      "Google Workspace Reseller 인증은 Google의 공식 파트너로서 Google Workspace 제품을 판매하고 관리할 수 있는 권한을 의미합니다. 이를 통해 고객에게 맞춤형 솔루션과 기술 지원을 제공합니다.",
  },
  {
    question: "Partner Sales Console은 무엇인가요?",
    answer:
      "Partner Sales Console은 리셀러가 고객 계정, 구독, 결제를 관리할 수 있는 도구입니다. 이를 통해 효율적으로 고객 서비스를 제공할 수 있습니다.",
  },
];

const CertificationPage = () => {
  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      {/* Section Header */}
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          Google Workspace Reseller 인증
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Google Workspace Reseller로서 귀사의 비즈니스에 새로운 가능성을 열어보세요. 신뢰받는 파트너로서 고객에게 최상의 서비스를 제공합니다.
        </p>
      </div>

      {/* Benefits Section */}
      <div className="mt-12 grid gap-12 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
        {benefits.map((benefit, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-8 hover:shadow-xl transition-shadow duration-300"
          >
            {/* Icon */}
            <div className="flex items-center justify-center w-16 h-16 bg-blue-100 text-blue-600 rounded-full mb-6 mx-auto text-3xl">
              {benefit.icon}
            </div>
            {/* Title */}
            <h3 className="text-xl font-semibold text-gray-800 mb-4 text-center">
              {benefit.title}
            </h3>
            {/* Description */}
            <p className="text-gray-600">{benefit.description}</p>
          </div>
        ))}
      </div>

      {/* Features Section */}
      <div className="mt-16 max-w-7xl mx-auto">
        <h3 className="text-3xl font-bold text-gray-800 text-center">주요 기능</h3>
        <p className="mt-4 text-lg text-gray-600 text-center">
          Google Workspace Reseller가 제공하는 강력한 기능들을 확인하세요.
        </p>
        <div className="mt-8 space-y-6">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <h4 className="text-xl font-semibold text-gray-800 mb-2">
                {feature.title}
              </h4>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>

      {/* FAQ Section */}
      <div className="mt-16 max-w-7xl mx-auto">
        <h3 className="text-3xl font-bold text-gray-800 text-center">FAQ</h3>
        <p className="mt-4 text-lg text-gray-600 text-center">
          자주 묻는 질문에 대한 답변을 확인하세요.
        </p>
        <div className="mt-8 space-y-6">
          {faq.map((item, index) => (
            <div
              key={index}
              className="bg-white shadow-lg rounded-lg p-6 hover:shadow-xl transition-shadow duration-300"
            >
              <h4 className="text-xl font-semibold text-gray-800 mb-2">
                {item.question}
              </h4>
              <p className="text-gray-600">{item.answer}</p>
            </div>
          ))}
        </div>
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <h3 className="text-xl font-bold text-gray-800">
          지금 바로 Google Workspace Reseller가 되어보세요!
        </h3>
        <p className="mt-4 text-gray-600">
          Google과 함께 성장하는 비즈니스를 시작하세요.
        </p>
        <button          
          className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
        >
              <a
            href="https://forms.gle/ZKdgbNnfZRoTrZq57"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          더 알아보기
          </a>
        </button>
      </div>
    </section>
  );
};

export default CertificationPage;
