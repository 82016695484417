import React, { useState, useEffect } from "react";
import { database } from "../firebase";
import { ref, onValue, update } from "firebase/database";
import RequireAdmin from "../hoc/RequireAdmin";
import { FaUserPlus, FaUserEdit, FaTimes } from "react-icons/fa";

const AddAdmin = () => {
  const [users, setUsers] = useState([]); // 전체 사용자 리스트
  const [selectedUsers, setSelectedUsers] = useState([]); // 선택된 사용자 UID

  // 사용자 목록 가져오기
  useEffect(() => {
    const usersRef = ref(database, "users");
    onValue(usersRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const userList = Object.entries(data).map(([uid, userInfo]) => ({
          uid,
          ...userInfo,
        }));
        setUsers(userList);
      }
    });
  }, []);

  // 선택된 사용자 업데이트
  const handleCheckboxChange = (uid) => {
    setSelectedUsers((prevSelected) =>
      prevSelected.includes(uid)
        ? prevSelected.filter((id) => id !== uid) // 이미 선택된 경우 제거
        : [...prevSelected, uid] // 선택된 경우 추가
    );
  };

  // 선택된 사용자들을 관리자 추가
  const handleAddAdmins = () => {
    if (selectedUsers.length === 0) {
      alert("사용자를 선택해주세요!");
      return;
    }

    const updates = selectedUsers.map((uid) => {
      const userRef = ref(database, `users/${uid}`);
      return update(userRef, { role: "admin" });
    });

    Promise.all(updates)
      .then(() => {
        alert("선택된 사용자가 관리자 권한을 부여받았습니다.");
        setSelectedUsers([]);
      })
      .catch((error) => {
        console.error("관리자 추가 중 오류:", error);
        alert("관리자 추가 중 오류가 발생했습니다.");
      });
  };

  // 선택된 사용자들을 일반 사용자로 변경
  const handleSetToUser = () => {
    if (selectedUsers.length === 0) {
      alert("사용자를 선택해주세요!");
      return;
    }

    const updates = selectedUsers.map((uid) => {
      const userRef = ref(database, `users/${uid}`);
      return update(userRef, { role: "user" });
    });

    Promise.all(updates)
      .then(() => {
        alert("선택된 사용자가 일반 사용자 권한으로 변경되었습니다.");
        setSelectedUsers([]);
      })
      .catch((error) => {
        console.error("사용자 변경 중 오류:", error);
        alert("사용자 변경 중 오류가 발생했습니다.");
      });
  };

  // 선택 취소
  const handleCancelSelection = () => {
    setSelectedUsers([]);
  };

  return (
    <div className="max-w-6xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-3xl font-bold text-center mb-8 text-gray-700">
        사용자 관리
      </h2>
      
      {/* Table */}
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse bg-gray-50 rounded-lg shadow-md overflow-hidden">
          <thead className="bg-indigo-100 text-gray-700">
            <tr>
              <th className="border border-gray-300 px-4 py-3 text-left">선택</th>
              <th className="border border-gray-300 px-4 py-3 text-left">UID</th>
              <th className="border border-gray-300 px-4 py-3 text-left">이메일</th>
              <th className="border border-gray-300 px-4 py-3 text-left">역할</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr
                key={user.uid}
                className={`${
                  index % 2 === 0 ? "bg-white" : "bg-gray-100"
                } hover:bg-indigo-50 transition duration-150`}
              >
                <td className="border border-gray-300 px-4 py-3">
                  <input
                    type="checkbox"
                    className="w-5 h-5 cursor-pointer"
                    checked={selectedUsers.includes(user.uid)}
                    onChange={() => handleCheckboxChange(user.uid)}
                  />
                </td>
                <td className="border border-gray-300 px-4 py-3">{user.uid}</td>
                <td className="border border-gray-300 px-4 py-3">{user.email}</td>
                <td className="border border-gray-300 px-4 py-3">
                  {user.role || "사용자"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Buttons */}
      <div className="flex flex-wrap gap-x-4 gap-y-2 mt-6 justify-center">
        <button
          className="flex items-center bg-blue-500 text-white px-6 py-2 rounded-md shadow hover:bg-blue-600 transition duration-200"
          onClick={handleAddAdmins}
        >
          <FaUserPlus className="mr-2" />
          선택된 사용자 관리자 추가
        </button>
        <button
          className="flex items-center bg-green-500 text-white px-6 py-2 rounded-md shadow hover:bg-green-600 transition duration-200"
          onClick={handleSetToUser}
        >
          <FaUserEdit className="mr-2" />
          선택된 사용자 일반 사용자로 변경
        </button>
        <button
          className="flex items-center bg-gray-500 text-white px-6 py-2 rounded-md shadow hover:bg-gray-600 transition duration-200"
          onClick={handleCancelSelection}
        >
          <FaTimes className="mr-2" />
          선택 취소
        </button>
      </div>
    </div>
  );
};

export default RequireAdmin(AddAdmin);
