import React from "react";
import {
  FaGoogle,
  FaDollarSign,
  FaTools,
  FaDatabase,
  FaRocket,
} from "react-icons/fa";
import "../css/Workway.css";
import workwayImage from "../images/image/workway.png"; // Update with correct path
import logoImage from "../images/logo/logo1.png"; // Update with correct path

const Workway = () => {
  const features = [
    {
      icon: <FaGoogle className="icon" />,
      title: "Google Workspace 전문화",
      description:
        "Google Workspace의 다양한 기능을 최적화하여 기업의 생산성을 극대화합니다. 맞춤형 솔루션을 제공하여 업무 효율성을 높입니다.",
    },
    {
      icon: <FaTools className="icon" />,
      title: "AppSheet",
      description:
        "AppSheet를 사용하여 업무 편의성 시스템을 지원합니다. 사용자 친화적인 인터페이스로 업무 효율성을 높입니다.",
    },
    {
      icon: <FaDatabase className="icon" />,
      title: "Google 드라이브 백업",
      description:
        "중요한 데이터를 Google 드라이브에 자동으로 백업하여 안전하게 보관합니다. 데이터 복구가 용이하며, 보안이 강화됩니다.",
    },
    {
      icon: <FaDollarSign className="icon" />,
      title: "경쟁력 있는 가격",
      description:
        "다양한 가격 옵션을 제공하여 비용 효율성을 극대화합니다. 고객의 예산에 맞춘 유연한 가격 정책을 제공합니다.",
    },
    {
      icon: <FaRocket className="icon" />,
      title: "전문적인 서비스",
      description:
        "전문 기술 지원팀이 신속하게 문제를 해결합니다. 고객 만족도를 높이기 위해 24/7 지원을 제공합니다.",
    },
  ];

  return (
    <div className="container">
      {/* Header */}
      <header className="workway-header">
        <div className="logo-container">
          {/* 로고 이미지 */}
          <img src={logoImage} alt="Workway Logo" className="logo" />
          {/* 헤더 텍스트 */}
          <div className="header-text">
            <h1>WorkWay</h1>
            <p>Google Workspace 전문 서비스 제공</p>
          </div>
        </div>
      </header>

      {/* Intro Section */}
      <section className="workway-intro">
        <h2>회사 소개</h2>
        <p>
          워크웨이는 <strong>Google Workspace</strong>를 전문적으로 판매하며,
          기업의 디지털 업무 환경을 혁신적으로 개선하는 믿음직한 IT 솔루션 제공
          회사입니다. 우리는 고객의 성공을 최우선으로 하여, 최적의 서비스를
          제공합니다.
        </p>
      </section>

      {/* Diagram Section */}
      <section className="workway-diagram">
        <h2>비즈니스 성공을 위한 통합 솔루션</h2>
        <img
          src={workwayImage}
          alt="Workway Diagram"
          className="diagram-image"
        />
      </section>

      {/* Features Section */}
      <section className="workway-features">
        <h2>워크웨이의 강점</h2>
        <div className="card-container">
          {features.map((feature, index) => (
            <div key={index} className="card">
              {feature.icon}
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Contact Section */}
      <section className="workway-contact">
        <h2>문의하기</h2>
        <p>
          Google Workspace와 워크웨이의 다양한 솔루션에 대해 더 알고 싶으신가요?
        </p>
        <p>지금 바로 문의하시면 최적의 디지털 업무 환경을 설계해드립니다!</p>
        <a
          href="https://forms.gle/ZKdgbNnfZRoTrZq57"
          target="_blank"
          rel="noopener noreferrer"
          className="contact-link"
        >
          문의하기
        </a>
      </section>
    </div>
  );
};

export default Workway;
