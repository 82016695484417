import React from "react";
import {
  FaEnvelope,
  FaCloud,
  FaLock,
  FaChartLine,
  FaDollarSign,
  FaFileAlt,
} from "react-icons/fa";

const ServiceIntro = () => {
  const services = [
    {
      title: "통합 커뮤니케이션 도구",
      description:
        "Gmail, Google Meet, Google Chat, Google Calendar 등을 통해 팀 간의 소통과 협업을 혁신적으로 개선합니다. 효율적인 커뮤니케이션은 생산성을 높이고 팀워크를 강화합니다.",
      details: [
        "맞춤형 도메인 이메일(@yourcompany.com) 제공",
        "고품질 화상 회의 및 실시간 메시징 지원",
        "팀원 간 일정 공유 및 스케줄 관리",
      ],
      icon: <FaEnvelope />,
    },
    {
      title: "실시간 협업 및 생산성 향상",
      description:
        "Google Docs, Sheets, Slides에서 실시간 편집 및 협업이 가능하며, 댓글과 채팅으로 효율적인 피드백을 제공합니다. 팀원 간 원활한 작업 환경을 제공합니다.",
      details: [
        "여러 사용자가 동시에 문서 편집 가능",
        "자동 저장 기능으로 데이터 손실 방지",
        "문서 내에서 직접 피드백 제공",
      ],
      icon: <FaFileAlt />,
    },
    {
      title: "안전하고 중앙화된 클라우드 스토리지",
      description:
        "Google Drive를 통해 모든 파일을 안전하게 저장하고 어디서든 접근할 수 있습니다. 데이터 보존과 파일 공유가 간편합니다.",
      details: [
        "15GB 이상의 클라우드 저장 공간 제공",
        "파일 버전 관리 및 복구 기능 지원",
        "보안 설정으로 파일 접근 권한 제어 가능",
      ],
      icon: <FaCloud />,
    },
    {
      title: "강력한 보안 및 관리 기능",
      description:
        "관리자 콘솔과 2단계 인증으로 데이터를 보호하며, Google Vault를 통해 데이터 보존 및 규정 준수를 지원합니다.",
      details: [
        "사용자 계정 및 디바이스 관리 기능 제공",
        "2단계 인증으로 추가적인 보안 계층 제공",
        "데이터 암호화 및 규정 준수 도구 포함",
      ],
      icon: <FaLock />,
    },
    {
      title: "유연성과 확장성",
      description:
        "비즈니스 규모에 따라 맞춤형 플랜 선택이 가능하며, 필요에 따라 사용자 수를 쉽게 추가하거나 감소할 수 있습니다.",
      details: [
        "Starter, Business Plus, Enterprise 플랜 제공",
        "유연한 사용자 추가 및 제거 가능",
        "자동 업데이트로 항상 최신 상태 유지",
      ],
      icon: <FaChartLine />,
    },
    {
      title: "비용 효율성",
      description:
        "하드웨어 비용 절감 및 유지보수 없이 최신 기능과 보안 업데이트가 자동 적용되는 SaaS 모델입니다.",
      details: [
        "사용한 만큼만 지불하는 합리적인 요금제",
        "IT 인프라 비용 절감 효과 제공",
        "무료 체험 플랜으로 시작 가능",
      ],
      icon: <FaDollarSign />,
    },
  ];

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      {/* Section Header */}
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          서비스 소개
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          Google Workspace로 비즈니스 생산성을 극대화하세요. 다양한 도구와
          강력한 기능으로 팀의 협업을 한 단계 끌어올립니다.
        </p>
      </div>

      {/* Services Grid */}
      <div className="mt-12 grid gap-12 md:grid-cols-2 lg:grid-cols-3 max-w-7xl mx-auto">
        {services.map((service, index) => (
          <div
            key={index}
            className="bg-white shadow-lg rounded-lg p-8 hover:shadow-xl transition-shadow duration-300"
          >
            {/* Icon */}
            <div className="flex items-center justify-center w-16 h-16 bg-blue-100 text-blue-600 rounded-full mb-6 mx-auto text-3xl">
              {service.icon}
            </div>
            {/* Title */}
            <h3 className="text-xl font-semibold text-gray-800 mb-4 text-center">
              {service.title}
            </h3>
            {/* Description */}
            <p className="text-gray-600 mb-6">{service.description}</p>
            {/* Details List */}
            <ul className="list-disc list-inside space-y-2 text-gray-600">
              {service.details.map((detail, idx) => (
                <li key={idx} className="flex items-start">
                  <span className="mr-2">•</span>
                  {detail}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Call to Action */}
      <div className="mt-16 text-center">
        <h3 className="text-xl font-bold text-gray-800">
          지금 바로 Google Workspace를 경험해보세요!
        </h3>
        <p className="mt-4 text-gray-600">
          무료 체험을 통해 비즈니스 환경을 혁신할 준비가 되셨나요? 지금
          시작하세요.
        </p>
        <button className="mt-6 px-6 py-3 bg-blue-600 text-white rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300">
        <a
            href="https://forms.gle/ZKdgbNnfZRoTrZq57"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
          더 알아보기
          </a>
        </button>
      </div>
    </section>
  );
};

export default ServiceIntro;
