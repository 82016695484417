import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { database } from "../firebase";
import { ref, push, set, onValue } from "firebase/database";
import { useAuth } from "../Component/AuthContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "../css/BoardCreate.css"; // CSS 파일 추가

const BoardCreate = () => {
  const [title, setTitle] = useState(""); // 제목 상태
  const [content, setContent] = useState(""); // 내용 상태 (에디터)
  const { user } = useAuth();
  const [boardTypeName, setBoardTypeName] = useState(""); // 게시판 타입 이름 상태
  const navigate = useNavigate();
  const { type } = useParams(); // 게시판 타입 가져오기

 useEffect(() => {
    if (!type) {
      navigate("/board/notice"); // Redirect to default type
    }

    if (type) {
      const boardTypeRef = ref(database, `boardTypes/${type}`);
      onValue(boardTypeRef, (snapshot) => {
        const data = snapshot.val();
        
        if (data) {
          setBoardTypeName(data);
        }
      });
    }    
  }, [type, navigate]);

  const addPost = () => {
    if (!title.trim() || !content.trim()) {
      alert("제목과 내용을 입력하세요!");
      return;
    }

    // 게시판 타입별 데이터 저장
    const postsRef = ref(database, `posts/${type}`);
    const newPostRef = push(postsRef);

    set(newPostRef, {
      title,
      content,
      author: user.email,
      views: 0,
      createdAt: new Date().toLocaleString(),
    })
      .then(() => {
        alert("게시물이 저장되었습니다!");
        navigate(`/board/${type}`); // 저장 후 해당 게시판으로 이동
      })
      .catch((error) => {
        console.error("글 추가 중 오류:", error);
        alert("글 저장 중 오류가 발생했습니다.");
      });
  };
  

  return (
    <div className="container mt-5">
      <div className="card shadow-lg p-5 bg-light">
        <h1 className="text-center mb-4 text-primary">{boardTypeName} 게시판 - 새 글 작성</h1>
        <div className="form-group mb-4">
          <label htmlFor="title" className="form-label">
            제목
          </label>
          <input
            type="text"
            id="title"
            className="form-control form-control-lg"
            placeholder="제목을 입력하세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </div>
        <div className="form-group mb-4">
          <label htmlFor="editor" className="form-label">
            내용
          </label>
          <div
            className="editor-container"
            style={{
              border: "1px solid #ced4da",
              borderRadius: "5px",
              padding: "10px",
              backgroundColor: "#fff",
            }}
          >
            <CKEditor
              editor={ClassicEditor}
              config={{
                licenseKey: "GPL",
                toolbar: [
                  "heading",
                  "|",
                  "bold",
                  "italic",
                  "link",
                  "bulletedList",
                  "numberedList",
                  "|",
                  "undo",
                  "redo",
                ],
              }}
              data={content}
              onReady={(editor) => {
                editor.ui.view.editable.element.style.minHeight = "300px"; // Set height dynamically
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setContent(data);
              }}
            />
          </div>
        </div>
        <div className="text-center">
          <button
            className="btn btn-primary px-4 me-3"
            onClick={addPost}
          >
            저장
          </button>
          <button
            className="btn btn-secondary px-4"
            onClick={() => navigate(`/board/${type}`)}
          >
            취소
          </button>
        </div>
      </div>
    </div>
  );
};

export default BoardCreate;
