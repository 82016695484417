import React from "react";
import "../css/home.css";
import Slider from "./Slide";
import Board from "./Board";
import ServiceIntro from "./ServiceIntro";
import SchedulePage from './SchedulePage';

function Home() {
  return (
    <div className="home">
      <header className="mb-8">
        <Slider />
      </header>
      <main className="container mx-auto px-4">
        
        {/* Service Section */}
        <ServiceIntro />
        {/* Board Section */}
        <Board type="notice" />
        {/* Schedule Section */}
        <SchedulePage />
        
      </main>
    </div>
  );
}

export default Home;
