import React from "react";
import "../css/Pricing.css";
import { FaCheckCircle } from "react-icons/fa";

function Pricing() {
  const plans = [
    {
      name: "비즈니스 스타터",
      description: "소규모 팀을 위한 모든 기능 제공",
      price: "₩7,800/사용자/월",
      features: [
        "30GB 클라우드 저장용량",
        "맞춤형 비즈니스 이메일",
        "영상 통화 최대 100명",
        "보안 및 관리 도구",
      ],
    },
    {
      name: "비즈니스 스탠다드",
      description: "중소기업에 적합한 더 많은 기능 제공",
      price: "₩15,600/사용자/월",
      features: [
        "2TB 클라우드 저장용량",
        "맞춤형 비즈니스 이메일",
        "영상 통화 최대 150명 + 녹화",
        "팀 협업 도구",
      ],
      highlight: true, // 강조 옵션
    },
    {
      name: "비즈니스 플러스",
      description: "고급 기능과 보안 제공",
      price: "₩23,400/사용자/월",
      features: [
        "5TB 클라우드 저장용량",
        "맞춤형 비즈니스 이메일",
        "영상 통화 최대 500명",
        "고급 보안 및 관리 도구",
      ],
    },
    {
      name: "엔터프라이즈",
      description: "대규모 기업에 맞춤형 솔루션 제공",
      price: "맞춤 가격",
      features: [
        "무제한 클라우드 저장용량",
        "고급 보안 및 분석 도구",
        "영상 통화 최대 500명 + 고급 기능",
        "맞춤형 지원",
      ],
    },
  ];

  return (
    <div className="pricing-container">
      <h1 className="pricing-title">요금제</h1>
      <p className="pricing-subtitle">
        비즈니스에 맞는 Google Workspace 요금제를 선택하세요.
      </p>
      <div className="pricing-grid">
        {plans.map((plan, index) => (
          <div
            key={index}
            className={`pricing-card ${plan.highlight ? "highlight" : ""}`}
          >
            {plan.highlight && <div className="badge">가장 인기 있는 옵션</div>}
            <h2 className="plan-name">{plan.name}</h2>
            <p className="plan-description">{plan.description}</p>
            <div className="plan-price">{plan.price}</div>
            <ul className="plan-features">
              {plan.features.map((feature, idx) => (
                <li key={idx}>
                  <FaCheckCircle className="icon" /> {feature}
                </li>
              ))}
            </ul>
            <button className="btn-select">시작하기</button>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Pricing;
