import React, { createContext, useContext, useEffect, useState } from "react";
import { onAuthStateChanged, signInWithPopup, signOut } from "firebase/auth";
import { auth, googleProvider } from "../firebase";
import { ref, set, get } from "firebase/database";
import { database } from "../firebase"; // Firebase Database 참조

// AuthContext 생성
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Firebase 인증 상태 변경 감지
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        // 로그인한 사용자 정보 가져오기
        const userRef = ref(database, `users/${currentUser.uid}`);
        const snapshot = await get(userRef);
        if (!snapshot.exists()) {
          // 데이터베이스에 사용자 정보가 없으면 추가
          await set(userRef, {
            uid: currentUser.uid,
            email: currentUser.email,
            name: currentUser.displayName,
            role: "user", // 기본 역할 설정
            createdAt: new Date().toISOString(),
          });
        }

        setUser({ ...currentUser, ...snapshot.val() });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Google 로그인 함수
  const loginWithGoogle = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const currentUser = result.user;

      // Firebase Database에 사용자 정보 저장
      const userRef = ref(database, `users/${currentUser.uid}`);
      const snapshot = await get(userRef);
      if (!snapshot.exists()) {
        await set(userRef, {
          uid: currentUser.uid,
          email: currentUser.email,
          name: currentUser.displayName,
          role: "user", // 기본 역할 설정
          createdAt: new Date().toISOString(),
        });
      }

      setUser({ ...currentUser, ...snapshot.val() });
    } catch (error) {
      console.error("Google 로그인 실패:", error);
    }
  };

  // 로그아웃 함수
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("로그아웃 실패:", error);
    }
  };

  // 관리자인지 확인하는 함수
  const isAdmin = () => {
    return user?.role === "admin";
  };

  return (
    <AuthContext.Provider value={{ user, loading, loginWithGoogle, logout, isAdmin }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// AuthContext 사용을 위한 커스텀 훅
export const useAuth = () => useContext(AuthContext);
