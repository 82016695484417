import React, { useState, useEffect } from "react";
import { database } from "../firebase";
import { ref, onValue, remove } from "firebase/database";
import { useAuth } from "../Component/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import "../css/Board.css";

const Board = ({ type: propType }) => {
  const { type: paramType } = useParams(); // URL에서 게시판 타입 가져오기
  const type = propType || paramType; // propType이 우선, 없으면 paramType 사용
  const [posts, setPosts] = useState([]); // 게시글 목록
  const { user, isAdmin } = useAuth();
  const navigate = useNavigate();
  const [boardTypeName, setBoardTypeName] = useState("");


  // Firebase에서 데이터 읽기
  useEffect(() => {
    if (!type) {
      navigate("/board/notice"); // Redirect to default type
    }

    if (type) {
      const boardTypeRef = ref(database, `boardTypes/${type}`);
      onValue(boardTypeRef, (snapshot) => {
        const data = snapshot.val();
        
        if (data) {
          setBoardTypeName(data);
        }
      });
    }

    const postsRef = ref(database, `posts/${type}`); // 게시판 타입별 데이터
    onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      const postList = data
        ? Object.entries(data).map(([id, value]) => ({ id, ...value }))
        : [];
      setPosts(postList);
    });
  }, [type, navigate]);

  // 게시글 삭제
  const deletePost = (id) => {
    if (window.confirm("정말로 삭제하시겠습니까?") === true) {
    const postRef = ref(database, `posts/${type}/${id}`);
    remove(postRef).catch((error) => console.error("글 삭제 중 오류:", error));
    }
  };

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      <div>
        <div className="max-w-7xl mx-auto text-center">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            {boardTypeName} 게시판
          </h2>
        </div>
        {user ? (
          <button
            className="btn btn-primary mb-4 pull-right"
            onClick={() => navigate(`/board/${type}/add`)}
          >
            글쓰기
          </button>
        ) : null}

        {/* 게시물 리스트 */}
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>제목</th>
              <th>작성자</th>
              <th>작성 날짜</th>
              <th>조회수</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
            {posts.map((post, index) => (
              <tr key={post.id}>
                <td>{index + 1}</td>
                <td
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => navigate(`/board/${type}/${post.id}`)}
                >
                  {post.title}
                </td>
                <td>{isAdmin ? "관리자" : post.author}</td>
                <td>{post.createdAt}</td>
                <td>{post.views}</td>
                <td>
                  {user && (user.email === post.author || isAdmin) && (
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => deletePost(post.id)}
                    >
                      삭제
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Board;
