import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Features from "./pages/Features";
import Solutions from "./pages/Solutions";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Navbar from "./pages/Navbar";
import Board from "./pages/Board";
import BoardCreate from "./pages/BoardCreate";
import BoardDetail from "./pages/BoardDetail";
import BoardTypeManager from "./pages/BoardTypeManager";
import Workway from "./pages/Workway";
import Footer from "./pages/Footer";
import ServiceIntro from "./pages/ServiceIntro";
import SuccessStories from "./pages/SuccessStories";
import SchedulePage from "./pages/SchedulePage";
import CertificationPage from "./pages/CertificationPage";
import { AuthProvider } from "./Component/AuthContext";
import AddAdmin from './pages/AddAdmin';
import AdminDashboard from './pages/AdminDashboard';


function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/features" element={<Features />} />
          <Route path="/solutions" element={<Solutions />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/board" element={<Board />} />
          <Route path="/board/:type" element={<Board />} />
          <Route path="/board/:type/add" element={<BoardCreate />} />
          <Route path="/board/:type/:id" element={<BoardDetail />} />
          <Route path="/board-types" element={<BoardTypeManager />} /> {/* 관리 페이지 */}
          <Route path="/addadmin" element={<AddAdmin />} /> {/* 관리 페이지 */}
          <Route path="/workadmin/*" element={<AdminDashboard />} />
          <Route path="/workway" element={<Workway />} />

          <Route path="/serviceintro" element={<ServiceIntro />} />
          <Route path="/successstories" element={<SuccessStories />} />
          <Route path="/schedulepage" element={<SchedulePage />} />
          <Route path="/certificationpage" element={<CertificationPage />} />
        </Routes>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
