import React, { useState } from "react";
import { IoIosSync } from "react-icons/io";
const SchedulePage = () => {
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태

  return (
    <section className="bg-gray-50 py-16 px-6 sm:px-12 lg:px-24">
      {/* Section Header */}
      <div className="max-w-7xl mx-auto text-center">
        <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
          교육 및 일정
        </h2>
        <p className="mt-4 text-lg text-gray-600">
          아래에서 교육 세션 및 일정을 확인하고 예약하세요.
        </p>
      </div>

      {/* Google Calendar Appointment Scheduling */}
      <div className="mt-12 max-w-7xl mx-auto relative">
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-100">
            <IoIosSync className="animate-spin" size={120} color="#4CAF50" />;
          </div>
        )}
        <iframe
          src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ2MwlP2uR9xj4uI3dN1LbzHKNKfALF3v3lWtT8nd7i4GvXljghAZ8URGWPi49s8amhgZEG8Y4lG?gv=true"
          style={{
            border: 0,
            width: "100%",
            height: "600px",
          }}
          frameBorder="0"
          title="Google Calendar Appointment Scheduling"
          onLoad={() => setIsLoading(false)} // 로드 완료 후 상태 변경
        ></iframe>
      </div>
    </section>
  );
};

export default SchedulePage;
