import React from 'react';
import '../css/Features.css';
import { FaEnvelope, FaFileAlt, FaChartBar, FaSlideshare, FaUsers, FaCalendarAlt, FaCloud } from 'react-icons/fa';
import featureImage from '../images/image/feature.png'; // 이미지 경로

function Features() {
  const features = [
    { name: "Gmail", description: "이메일 보안, 효율적 커뮤니케이션", icon: <FaEnvelope /> },
    { name: "Docs", description: "실시간 편집 및 팀 협업", icon: <FaFileAlt /> },
    { name: "Sheets", description: "데이터 분석 및 계산 처리", icon: <FaChartBar /> },
    { name: "Slides", description: "프레젠테이션 제작 및 디자인", icon: <FaSlideshare /> },
    { name: "Meet", description: "화상 회의와 원격 커뮤니케이션", icon: <FaUsers /> },
    { name: "Calendar", description: "일정 관리 및 팀 공유", icon: <FaCalendarAlt /> },
    { name: "Drive", description: "파일 보관 및 파일 공유", icon: <FaCloud /> },
  ];

  return (
    <div className="features-container">
      <h1 className="features-title">Google Workspace 주요 기능</h1>
      <p className="features-subtitle">협업과 생산성을 극대화하는 도구들</p>

      {/* 이미지 다이어그램 */}
      <div className="features-image">
        <img src={featureImage} alt="Google Workspace 기능 다이어그램" />
      </div>

      {/* 카드 레이아웃 */}
      <div className="features-grid">
        {features.map((feature, index) => (
          <div key={index} className="feature-card">
            <div className="feature-icon">{feature.icon}</div>
            <h2 className="feature-name">{feature.name}</h2>
            <p className="feature-description">{feature.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Features;
