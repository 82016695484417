import React, { useState } from "react";
import logo from "../images/logo/logo1.png"; // Import the logo
import { Link, Route, Routes } from "react-router-dom";
import RequireAdmin from "../hoc/RequireAdmin";

import {
  FaChevronDown,
  FaChevronUp,
  FaClipboard,
  FaUser,
} from "react-icons/fa";
import AddAdmin from "./AddAdmin";
import BoardTypeManager from "./BoardTypeManager"; // Import the BoardTypeManager component

const AdminDashboard = () => {
  const [openMenu, setOpenMenu] = useState(null); // Track which submenu is open

  const toggleMenu = (menu) => {
    setOpenMenu(openMenu === menu ? null : menu); // Toggle submenu
  };

  return (
    <div className="flex min-h-screen bg-gray-50">
      {/* Sidebar */}
      <aside className="w-64 bg-white text-gray-800 h-screen shadow-md">
        {/* Logo */}
        <div className="p-6 flex items-center justify-between border-b border-gray-300">
          <span className="flex items-center">
            <img
              src={logo}
              alt="WORKWAY Logo"
              className="w-8 h-10 mr-5" // Adjusted size: width 8 and height 8 (Tailwind units)
            />
            <span className="text-xl font-bold">Admin</span>
          </span>
        </div>

        {/* Sidebar Menu */}
        <nav className="mt-4">
          {/* 게시판 관리 Section */}
          <div className="mb-6">
            <h3 className="px-6 text-xs font-semibold text-gray-400 uppercase">
              게시판 관리
            </h3>
            <ul>
              <li>
                <button
                  onClick={() => toggleMenu("boardManagement")}
                  className="flex items-center justify-between w-full px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300"
                >
                  <span className="flex items-center">
                    <FaClipboard className="mr-3" />
                    게시판 관리
                  </span>
                  {openMenu === "boardManagement" ? (
                    <FaChevronUp size={14} />
                  ) : (
                    <FaChevronDown size={14} />
                  )}
                </button>
                {/* Submenu */}
                <ul
                  className={`ml-8 mt-2 space-y-2 overflow-hidden transition-all duration-500 ease-in-out ${
                    openMenu === "boardManagement"
                      ? "max-h-screen opacity-100"
                      : "max-h-0 opacity-0"
                  }`}
                >
                  <li>
                    <Link
                      to="/workadmin/board-management"
                      className="block px-4 py-2 hover:bg-gray-100 rounded"
                    >
                      게시판 이름 관리
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>

          {/* 사용자 관리 Section */}
          <div className="mb-6">
            <h3 className="px-6 text-xs font-semibold text-gray-400 uppercase">
              사용자 관리
            </h3>
            <ul>
              <li>
                <Link
                  to="/workadmin/user-management"
                  className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300"
                >
                  <FaUser className="mr-3" />
                  사용자 관리
                </Link>
              </li>
            </ul>
          </div>

          {/* Custom Section */}
          <div className="mb-6 hidden">
            <h3 className="px-6 text-xs font-semibold text-gray-400 uppercase">
              Custom
            </h3>
            <ul>
              {/* Pages */}
              <li className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300">
                Pages
              </li>

              {/* Authentication */}
              <li className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300">
                Authentication
              </li>

              {/* Widgets */}
              <li className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300">
                Widgets
              </li>
            </ul>
          </div>

          {/* Components Section */}
          <div className="hidden">
            <h3 className="px-6 text-xs font-semibold text-gray-400 uppercase">
              Components
            </h3>
            <ul>
              {/* Base UI */}
              <li className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300">
                Base UI
              </li>

              {/* Advanced UI */}
              <li className="flex items-center px-6 py-3 hover:bg-gray-100 rounded transition-all duration-300">
                Advanced UI
              </li>
            </ul>
          </div>
        </nav>
      </aside>

      {/* Main Content Area */}
      <main className="w-full p-8 bg-white shadow-md">
        {/* Routes for Main Content */}
        <Routes>
          {/* Default Route to AddAdmin */}
          <Route path="/" element={<AddAdmin />} />
          {/* User Management Route */}
          <Route path="user-management" element={<AddAdmin />} />

          {/* Post Management Route */}
          <Route path="board-management" element={<BoardTypeManager />} />
        </Routes>
      </main>
    </div>
  );
};

export default RequireAdmin(AdminDashboard);
