import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../css/Navbar.css";
import logo from "../images/logo/logo1.png";
import { useAuth } from "../Component/AuthContext";

function Navbar() {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { user, loginWithGoogle, logout,isAdmin } = useAuth();

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  const renderNavLinks = () => (
    <>
      <li><Link to="/">홈</Link></li>
      <li><Link to="/serviceintro">서비스 소개</Link></li>
      <li><Link to="/board/notice">공지사항</Link></li>
      <li><Link to="/successstories">성공 사례</Link></li>
      <li><Link to="/schedulepage">교육 신청</Link></li>
      <li><Link to="/certificationpage">기술 인증</Link></li>
      <li><Link to="/board/event">이벤트</Link></li>
      <li>
        <a
          href="https://forms.gle/ZKdgbNnfZRoTrZq57"
          target="_blank"
          rel="noopener noreferrer"
        >
          무료 체험
        </a>
      </li>
      {user && isAdmin && (
        <li><Link to="/workadmin">관리자 페이지</Link></li>
      )}
     
    </>
  );

  const renderAuthButton = () => (
    !user ? (
      <li className="auth-button">
        <button onClick={loginWithGoogle} className="btn-login">
          로그인
        </button>
      </li>
    ) : (
      <li className="user-info">
        <div className="greeting">
          안녕하세요, <span className="user-name">{user.displayName || "사용자"}</span>님
        </div>
        <button onClick={logout} className="btn-logout">
          로그아웃
        </button>
      </li>
    )
  );

  return (
    <div className="navbar-container">
      <nav className="navbar">
        <div className="navbar-logo">
          <Link to="/" aria-label="Go to homepage">
            <img src={logo} alt="WORKWAY Logo" />
          </Link>
        </div>
        <button
          className={`hamburger-icon ${isMobileMenuOpen ? "open" : ""}`}
          onClick={toggleMobileMenu}
          aria-expanded={isMobileMenuOpen}
          aria-label={isMobileMenuOpen ? "Close navigation menu" : "Open navigation menu"}
        >
          ☰
        </button>
        <ul
          className={`nav-links ${isMobileMenuOpen ? "mobile-open" : ""}`}
          aria-hidden={!isMobileMenuOpen}
          onClick={() => setIsMobileMenuOpen(false)}
        >
          {renderNavLinks()}
          {renderAuthButton()}
        </ul>
      </nav>
    </div>
  );
}

export default Navbar;
