import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { database } from "../firebase";
import { ref, get, update } from "firebase/database";
import "../css/BoardDetail.css";

// react-icons에서 Font Awesome 아이콘 import
import { FaCalendarAlt, FaUser, FaEye, FaClipboardList } from "react-icons/fa";

const BoardDetail = () => {
  const { id, type = "notice" } = useParams(); // Get id and type (default to "notice")
  const navigate = useNavigate();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    const fetchPost = async () => {
      const postRef = ref(database, `posts/${type}/${id}`); // Use type in the path
      const snapshot = await get(postRef);
      if (snapshot.exists()) {
        const postData = snapshot.val();
        setPost(postData);
        update(postRef, { views: (postData.views || 0) + 1 }); // Increment views
      } else {
        alert("해당 게시글이 존재하지 않습니다.");
        navigate(`/${type}`);
      }
      setLoading(false);
    };

    fetchPost();
  }, [id, type, navigate]);

  if (loading) return <div className="text-center mt-5">로딩 중...</div>;

  return (
    <div className="board-detail-container">
      <div className="board-detail-header">
        <h2 className="board-title">{post.title}</h2>
        <div className="board-meta">
          <span>
            <FaCalendarAlt /> {post.createdAt}
          </span>{" "}
          |{" "}
          <span>
            <FaUser /> {post.author}
          </span>{" "}
          |{" "}
          <span>
            <FaEye /> {post.views} 조회
          </span>
        </div>
      </div>
      <div className="board-detail-content">
        <div dangerouslySetInnerHTML={{ __html: post.content }} />
      </div>
      <div className="board-footer">
        <button
          className="btn-back-to-list"
          onClick={() => navigate(`/board/${type}`)} // Navigate back to the appropriate board type
        >
          <FaClipboardList /> 글목록
        </button>
      </div>
    </div>
  );
};

export default BoardDetail;
